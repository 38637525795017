import "./cardStyles/backInfo.css"

function BackInfo() {
    return (
        <div className="wrapperinfo">
             <p className="title">current way of collecting money</p>
            <div className="account">
               
                <p>
                    Account Number
                </p>
                <p>
                    1000 559 729 314
                </p>
            </div>

            <div className="current">
                <p>Current Amount
                </p>
                <p className="amunt">650 ETB</p>

            </div>
            
          
           <p className="desc2">
            Anyone can utilize the aforementioned 
            Commercial Bank of Ethiopia <br></br>
            account number 
            to help us in our mission.
          
            </p>
            <p className="desc3"> We will notify you of the current status every day.</p>


        </div>
    )
}
export default BackInfo;