import "./cardStyles/footer.css"

function FooterCard(){
    return(
        <div className="fcard">
            <div className="container">
                <h1>Logo</h1>
                <h1>Name of the company</h1>
                <h4>
                Description about the company
                </h4>

            </div>

        </div>
    )
}

export default FooterCard;