import "../Styles/footer.css"
import FooterCard from "../cards/footer_card"
function Footer(){
    return(
        <footer className="footer">
            <FooterCard></FooterCard>

            <div className="f-container">

                <div className="follow">
                    <h2>Follow us</h2>
                    <div className="icons">
                    <p>TG</p>
                    <p>FB</p>
                    <p>Twitter</p>
                    </div>
                </div>

                <div className="subscribe">

                    <h1>Comment us</h1>
                    <div className="subscribe">
                        <textarea  placeholder="Message" className="message"></textarea><br></br>

                        <input type="email" placeholder="Email" className="email"></input><button>Submit</button>
                        

                    </div>

                </div>
                <hl></hl>

            </div>
           

        </footer>
    )
}

export default Footer