
import Navbar from "./Components/header";
import MainBody from "./Components/body";
import Holder from "./Components/cardHolder";
import Footer from "./Components/footer";
import './App.css';
import React from "react";
import "./Styles/main.css"
function App() {
  return (
 <React.Fragment>
  <Navbar></Navbar>
  <MainBody></MainBody>
  <Holder></Holder>
  <Footer></Footer>

 </React.Fragment>
  );
}

export default App;
