import { FaBars, FaTimes } from "react-icons/fa"
import { useRef } from "react";
import "../Styles/header.css";

function Navbar() {
  const navref = useRef();
  const showNavBar = () => {
    navref.current.classList.toggle("responsive_nav")
  }
  return (
    <header>
      <h2>Logo</h2>
      <nav ref={navref}>
        <a href="#">Home</a>
        <a href="#">Goal</a>
        <a href="#">Blog</a>
        <a href="#">About us</a>
        <a href="#">Contacts</a>
        <button onClick={showNavBar} className="nav-btn nav-close-btn">
          <FaTimes></FaTimes>
        </button>
      </nav>
      <button onClick={showNavBar} className="nav-btn">
        <FaBars></FaBars>
      </button>
    </header>
  );
}

export default Navbar;