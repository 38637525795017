import "../cardStyles/reason.css"

function Reason() {
    return (
        <div className="r-wrapp">
            <h2>ችግሮች</h2>
            <p> Not expansion of art of faith (توحيد)
            </p>
            <p>Limited number of sunna Ustaz’s
            </p>
            <p>Limited number Madrasah
            </p>
            <p>
            Not being stand up for big goal
            </p>
            <p>Not understanding what was payed </p>

        </div>
    )
}
export default Reason