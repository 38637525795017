import quran1 from "../images/qu1.jpg"
import stud1 from "../images/st1.jpg"
import tewhid from "../images/tewhid.jpg"
import "./cardStyles/future.css"
function FutureTask(){
    return(
        <div className="wrapper">
         <h3>Some Future planes</h3>

         <div className="im1-wrapper">
            <img src={quran1} className="quran"></img>
            <img src={stud1} className="students"></img>
         </div>
         
         <div className="calling">
            <p>Calling The People to Tewhid</p>
            <img src={tewhid}></img>

         </div>


        </div>
    )
}
export default FutureTask;